/* eslint-disable default-case */
import { useState, useEffect } from 'react';
import { Auth, Hub } from 'aws-amplify';
import Mastheader from './Mastheader';
import SignIn from './SignIn';

import Theme from '@amzn/meridian/theme';
import AppLayout from '@amzn/meridian/app-layout';
import blueLightTokens from '@amzn/meridian-tokens/theme/blue-light';
import ReportForm from './ReportForm';
import Footer from './Footer';
function App() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    Hub.listen('auth', ({ payload: { event, data } }) => {
      switch (event) {
        case 'signIn':
          getUser().then((userData) => setUser(userData));
          break;
        case 'cognitoHostedUI':
          getUser().then((userData) => setUser(userData));
          break;
        case 'signOut':
          setUser(null);
          break;
        case 'signIn_failure':
          break;
        case 'cognitoHostedUI_failure':
          console.log('Sign in failure', data);
          break;
      }
    });

    getUser().then((userData) => setUser(userData));
  }, []);

  function getUser() {
    return Auth.currentAuthenticatedUser()
      .then((userData) => userData)
      .catch(() => console.log('Not signed in'));
  }
  return (
    <>
      {user ? (
        <Theme tokens={blueLightTokens}>
          <AppLayout
            headerComponent={Mastheader}
            footerComponent={Footer}
            spacingInset='small small'
          >
            <Mastheader />
            <Footer />
            <ReportForm />
          </AppLayout>
        </Theme>
      ) : (
        <SignIn />
      )}
    </>
  );
}

export default App;
