import React from 'react';
import { colorGray200 } from '@amzn/meridian-tokens/base/color';
import Column from '@amzn/meridian/column';
import Row from '@amzn/meridian/row';
import Text from '@amzn/meridian/text';
import Link from '@amzn/meridian/link';

const footerStyle = {
  borderTop: `1px solid ${colorGray200}`,
};

const Footer = () => {
  return (
    <div style={footerStyle}>
      <Column
        alignmentHorizontal='center'
        backgroundColor='alternateSecondary'
        spacingInset='medium large'
      >
        <Row alignmentHorizontal='center' width={950} maxWidth='100%'>
          <Text>Confidential, for Amazon use only.</Text>
        </Row>
      </Column>
    </div>
  );
};

export default Footer;
