/* eslint-disable no-restricted-globals */
import Masthead, { MastheadTitle } from '@amzn/meridian/masthead';
import Text from '@amzn/meridian/text';
import Button from '@amzn/meridian/button';
import { Auth } from 'aws-amplify';
const Mastheader = () => {
  return (
    <Masthead>
      <MastheadTitle href='/' onClick={history.push}>
        <Text type='h200'>Transcript Manager Service</Text>
      </MastheadTitle>
      <Button onClick={() => Auth.signOut()}>Sign Out</Button>
    </Masthead>
  );
};

export default Mastheader;
