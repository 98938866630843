import React, { useState, version } from 'react';
import Column from '@amzn/meridian/column';
import Row from '@amzn/meridian/row';
import Button from '@amzn/meridian/button';
import Input from '@amzn/meridian/input';
import Heading from '@amzn/meridian/heading';
import Select, { SelectOption } from '@amzn/meridian/select';
import Alert from '@amzn/meridian/alert';
import Divider from '@amzn/meridian/divider';
import FileInput, { FileDetails } from '@amzn/meridian/file-input';
import ReportImage from './ReportImage';
import Amplify, { API } from 'aws-amplify';
import { Auth } from 'aws-amplify';
import Loader from "@amzn/meridian/loader";
import Text from "@amzn/meridian/text"

//I found this function in a Meridian example. Is handy for validating form fields
const isValid = ({ possibleErrors = [], requiredValues = [] }) =>
  possibleErrors.every((error) => error === false) &&
  requiredValues.every((value) => value !== '' && value !== undefined);

  
const fileHasCsvExtension = (fileName) => {
  return fileName && fileName.split('.').pop() === 'csv';
};

const isAccountLink = (accountLink) => {
  return accountLink === 'Yes';
};

const ReportForm = () => {
  const sanityValues = [
    { label: 'Yes', value: 'yes' },
    { label: 'No', value: 'no' },
  ];

  const accountLinkValues = [
    { label: 'Yes', value: 'yes' },
    { label: 'No', value: 'no' },
  ];

  const mdiReportValues = [
    { label: 'Yes', value: 'yes' },
    { label: 'No', value: 'no' },
  ];

  const stageValues = [
    { label: 'Certification', value: 'certification' },
    { label: 'Live', value: 'live' },
    // { label: 'Development Stage', value: 'development' },
  ];

  const localeValues = [
    { label: 'en-US', value: 'en-US' },
    { label: 'en-CA', value: 'en-CA' },
    { label: 'en-GB', value: 'en-GB' },
    { label: 'en-IN', value: 'en-IN' },
    { label: 'en-AU', value: 'en-AU' },
    { label: 'fr-FR', value: 'fr-FR' },
    { label: 'fr-CA', value: 'fr-CA' },
    { label: 'es-MX', value: 'es-MX' },
    { label: 'es-ES', value: 'es-ES' },
    { label: 'es-US', value: 'es-US' },
    { label: 'it-IT', value: 'it-IT' },
    { label: 'de-DE', value: 'de-DE' },
  ];

  const [skillId, setSkillId] = useState();
  const [skillVersion, setSkillVersion] = useState();
  const [ecid, setEcid] = useState();
  const [stage, setStage] = useState();
  const [sanity, setSanityValue] = useState();
  const [locale, setLocaleValue] = useState();
  const [accountLink, setAccountLinkValue] = useState(null);
  const [mdiReport, setMdiReport] = useState();
  const [skillIdError, setSkillIdError] = useState();
  const [skillVersionError, setSkillVersionError] = useState();
  const [ecidError, setEcidError] = useState();
  const [localeError, setLocaleError] = useState();

  const [percentage, setPercentage] = useState(undefined);
  const [files, setFiles] = useState([]);
  const [loaded, setLoaded] = useState(true);
  const isReadyToSubmit = isValid({
    possibleErrors: [skillIdError, skillIdError],
    requiredValues: [skillId, skillVersion, ecid, locale],
  });

  const isReadyToSubmitWithoutAccLink = isValid({
    possibleErrors: [skillIdError, skillIdError],
    requiredValues: [skillId, skillVersion],
  });

  const handleSubmit = async (e) => {
    setLoaded(false);
    e.preventDefault();
    
    let user = await Auth.currentAuthenticatedUser();

    setSkillId();
    setSkillVersion();
    setStage();
    setEcid();
    // setMdiReport();
    setSanityValue();
    setLocaleValue();
    setAccountLinkValue();
    
    const init = {
      body: {
        skillId : skillId,
        version : skillVersion,
        stage : ( stage === undefined ? "certification" : stage ),
        ecid : ecid,
        isSanityEligible : ( sanity === 'yes' ? true : false ),
        emailId : user.attributes.email,
        inputLocale : locale
      }
    };

    const url = window.location.href;
    var endpoint = "";
    if (url.includes("gamma") || url.includes("localhost")) {
      endpoint = "https://4hfjiidkya.execute-api.us-east-1.amazonaws.com/dev";
    } else if (url.includes("prod")) {
      endpoint = "https://1g006n2w4d.execute-api.us-east-1.amazonaws.com/dev";
    }

    Amplify.configure({
      API: {
        endpoints: [
          {
            name: "createTranscripts",
            endpoint: endpoint,
            custom_header: async () => { 
              return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` }
            }
          }
        ]
      }
    });

    console.log(`Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`);

    await API.post("createTranscripts", "/transcripts", init).then(async (response) => {
      alert("Transcript Generation started. Wait for an email confirmation!")
    })
    .catch((error) => {
      alert("Something went wrong!")
    });
    setLoaded(true);
  };

  return (
    <>
    { !loaded ? <form >
                <Row alignmentHorizontal='center' spacingInset='large'>
                <Loader size="large"/>
                </Row> 
                </form> : 
      <form onSubmit={handleSubmit}>
        <Row alignmentHorizontal='center' spacingInset='large'>
          <Heading level={2} type='d50'>
            <ReportImage />
            Generate Transcript Manager Report
          </Heading>
        </Row>
        <Column alignmentHorizontal='center'>
        <Row width='50%' alignmentHorizontal='left'>
          {/* <Text><b> Note: </b> Make sure to manually account link the skill in your test account before generating the report. Refer SOP <a href="https://wiki.labcollab.net/confluence/display/Doppler/Create+Transcript+Manager+Report+for+Account+Linking+Skills">here</a>.</Text> */}
          </Row>
          <Column>
            <Heading level={4}>Skill Details</Heading>
            <Row
              widths='grid-8'
              alignmentVertical='top'
              alignmentHorizontal='justify'
            >
              <Column spacing='small'>
                <Input
                  label='Amazon Skill ID'
                  value={skillId}
                  onChange={(value) => setSkillId(value)}
                  onBlur={() => setSkillIdError(!skillId)}
                  error={skillIdError}
                />
                {skillIdError && (
                  <Alert type='error' size='small'>
                    Amazon Skill ID Required
                  </Alert>
                )}
              </Column>
              <Column spacing='small'>
                <Input
                  label='Skill Version'
                  value={skillVersion}
                  onChange={(value) => setSkillVersion(value)}
                  onBlur={() => setSkillVersionError(!skillVersion)}
                  error={skillVersionError}
                  type="number"
                />
                {skillVersionError && (
                  <Alert type='error' size='small'>
                    Skill Version Requried
                  </Alert>
                )}
              </Column>
            </Row>
            <Row
              widths='grid-8'
              alignmentHorizontal='justify'
              alignmentVertical='top'
            >
              <Column spacing='small'>
                <Select
                  label='Skill Stage'
                  value={stage}
                  onChange={(value) => setStage(value)}
                >
                  {stageValues.map(({ label, value }) => (
                    <SelectOption key={value} label={label} value={value} />
                  ))}
                </Select>
              </Column>
              <Column spacing='small'>
                <Select
                  label='Sanity Report'
                  value={sanity}
                  onChange={(value) => setSanityValue(value)}
                >
                  {sanityValues.map(({ label, value }) => (
                    <SelectOption key={value} label={label} value={value} />
                  ))}
                </Select>
              </Column>
            </Row>
            <Heading level={4}>Account Linking Details</Heading>
            <Row
              widths='grid-8'
              alignmentHorizontal='justify'
              alignmentVertical='top'
            >
              <Column spacing='small'>
              <Select
                  label='Is Account Linking Skill?'
                  value={accountLink}
                  onChange={(value) => { setAccountLinkValue(value) 
                  if (value === 'no') {setEcidError(false); setLocaleError(false)} }}
                >
                  {accountLinkValues.map(({ label, value }) => (
                    <SelectOption key={value} label={label} value={value} />
                  ))}
                </Select>
                </Column>
            </Row>
            <Row
              widths='grid-8'
              alignmentHorizontal='justify'
              alignmentVertical='top'
            >
              <Column spacing='small'>
                <Input
                  label='ECID'
                  disabled={accountLink === null || accountLink == 'no'}
                  value={ecid}
                  onChange={(value) => setEcid(value)}
                  onBlur={() => setEcidError(!ecid)}
                  error={ecidError}
                />
                {ecidError && (
                  <Alert type='error' size='small'>
                    ECID Requried
                  </Alert>
                )}
              </Column>
              <Column spacing='small'>
                <Select
                  label='Locale'
                  value={locale}
                  disabled={accountLink === null || accountLink == 'no'}
                  onChange={(value) => setLocaleValue(value)}
                  onBlur={() => setLocaleError(!locale)}
                  error={localeError}
                >
                  {localeValues.map(({ label, value }) => (
                    <SelectOption key={value} label={label} value={value} />
                  ))}
                </Select>
                {localeError && (
                  <Alert type='error' size='small'>
                    Locale Requried
                  </Alert>
                )}
              </Column>
              </Row>
              {/* <Row
              widths='grid-8'
              alignmentHorizontal='justify'
              alignmentVertical='top'
            >
              <Column spacing='small'>
                <Select
                  label='MDI Report'
                  value={mdiReport}
                  onChange={(value) => setMdiReport(value)}
                  disabled={true}
                >
                  {mdiReportValues.map(({ label, value }) => (
                    <SelectOption key={value} label={label} value={value} />
                  ))}
                </Select>
              </Column>
            </Row> */}
          </Column>
          <Row width='50%' alignmentHorizontal='right'>
            {/* <Button size='medium' submit={true} disabled={!isReadyToSubmit}> */}
            <Button size='medium' submit={true} disabled={accountLink === 'yes' ? !isReadyToSubmit : !isReadyToSubmitWithoutAccLink}>
              Generate Report
            </Button>
          </Row>
          <Divider size='small' />
          <Row width='50%' alignmentHorizontal='center'>
          <Text><a href="https://wiki.labcollab.net/confluence/display/Doppler/Create+Transcript+Manager+Report+for+Account+Linking+Skills">Account Linking SOP</a></Text>
          </Row>
          <Row width='50%' alignmentHorizontal='center'>
          <Text><a href="https://wiki.labcollab.net/confluence/display/Doppler/Transcript+Manager+Service+-+Main+Page">Transcript Manager Wiki</a></Text>
          </Row>
          <Row width='50%' alignmentHorizontal='center'>
          <Text>Non custom skills and skills with the below badges are not supported.</Text>
          </Row>
         <Row width='50%' alignmentHorizontal='center'>
          <Text>HTML,BULK_SKILL,CUSTOM_INTERFACE,INSTANT_PUBLISH,PrivateSkill,PRIVATE_SKILL</Text>
          </Row>
          <Row width='50%' alignmentHorizontal='center'>
          <Text>APP_LINKS,ASP,AMAZON_PAY,COLTRANE,Coltrane,A4H,PRIVATE_SKILL,ALEXA_FOR_HOSPITALITY</Text>
          </Row>
          {/* <Divider size='small' />
          <Heading level={4}>Or Upload a .csv File</Heading>
          <FileInput
            accept='.csv'
            disabled='true'
            onFileAttached={(acceptedFiles) => {
              acceptedFiles.forEach((file) => {
                console.log(file);
                file.uploadedFileHref = 'https://www.google.com';
              });
              setFiles([...files, ...acceptedFiles]);
            }}
          >
            {files.map((file) => {
              return (
                <FileDetails
                  error={file.error}
                  errorMessage={file.errorMessage}
                  file={file}
                  connectionSpeed='5MB/s'
                  uploadPercentage={percentage}
                  key={file.name}
                  onClickCancel={() => {
                    file.cancel();
                    const updatedFileArr = files.filter(
                      (fl) => fl.name !== file.name
                    );
                    setFiles(updatedFileArr);
                  }}
                  onClickRemoveFile={() => {
                    const updatedFileArr = files.filter(
                      (fl) => fl.name !== file.name
                    );
                    setFiles(updatedFileArr);
                  }}
                  uploadComplete={percentage === 100}
                />
              );
            })}
          </FileInput> */}
        </Column>
      </form>
}
    </>
  );
};

export default ReportForm;