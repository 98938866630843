import React from 'react';
import { Auth } from 'aws-amplify';
import Button from '@amzn/meridian/button';
import Column from '@amzn/meridian/column';
import Card, { CardActionBar, CardHeader } from '@amzn/meridian/card';
import Heading from '@amzn/meridian/heading';

const SignIn = () => {
  return (
    <div>
      <Column
        alignmentHorizontal='center'
        alignmentVertical='bottom'
        spacingInset='600'
      >
        <Card>
          <CardHeader>
            <Heading level={2} type='h500'>
              Sign in with Midway
            </Heading>
          </CardHeader>
          <CardActionBar widths='fill'>
            <Button
              onClick={() => Auth.federatedSignIn({ provider: 'MidwayOIDC' })}
            >
              Sign In
            </Button>
          </CardActionBar>
        </Card>
      </Column>
    </div>
  );
};

export default SignIn;
